import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SingleProduct from "../search/product";
import { SearchLoading } from "../shared/loading";
import Link from "next/link";
import { GET } from "../../utils/requests";
import { useSelector } from "react-redux";

const HomeOffer = ({ title, subtitle, url, redirect, text }) => {
	const [data, setData] = useState();
	const { zipData } = useSelector((s) => s.index);

	useEffect(async () => {
		const data = await fetch(url).then((s) => s.json());
		setData(data.items);
	}, [zipData?.zipcode]);

	return (
		<div className="home-full d-flex flex-column justify-content-center">
			<h1 className="text-dark fs-4 fw-bold">{title}</h1>
			<p className="text-info">{subtitle}</p>
			<Row className="justify-content-between">{!data ? <SearchLoading count={4} /> : data.map((x) => <SingleProduct {...x} />)}</Row>
			<div className="text-center mt-5">
				<Link href={redirect}>
					<a href={redirect} className="fw-bold border-2  border-info rounded- px-4 py-3 font-e-lg rounded-pill">
						{text}
					</a>
				</Link>
			</div>
		</div>
	);
};

export default HomeOffer;
